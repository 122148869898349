import { ref } from '@nuxtjs/composition-api'

const useCountryOverview = () => {
  const countries = ref([
    {
      code: 'DE',
      regex: /^(DE[\d]{9})$/i,
      translation: 'select.country.de'
    },
    {
      code: 'AT',
      regex: /^(ATU[\d]{8})$/i,
      translation: 'select.country.at'
    },
    {
      code: 'BE',
      regex: /^(BE[\d]{10})$/i,
      translation: 'select.country.be'
    },
    {
      code: 'NL',
      regex: /^NL\d{9}B\d{2}$/i,
      translation: 'select.country.nl'
    },
    {
      code: 'LU',
      regex: /^(LU[\d]{8})$/i,
      translation: 'select.country.lu'
    },
    {
      code: 'FR',
      regex: /^(FR[\d]{11})$/i,
      translation: 'select.country.fr'
    },
    {
      code: 'PL',
      regex: /^(PL[\d]{10})$/i,
      translation: 'select.country.pl'
    },
    {
      code: 'PT',
      regex: /^(PT[\d]{9})$/i,
      translation: 'select.country.pt'
    },
    {
      code: 'IT',
      regex: /^(IT[\d]{11})$/i,
      translation: 'select.country.it'
    },
    {
      code: 'HU',
      regex: /^(HU[\d]{8})$/i,
      translation: 'select.country.hu'
    },
    {
      code: 'CZ',
      regex: /^(CZ[\d]{8,10})$/i,
      translation: 'select.country.cz'
    },
    {
      code: 'BG',
      regex: /^(BG[\d]{9,10})$/i,
      translation: 'select.country.bg'
    },
    {
      code: 'HR',
      regex: /^(HR[\d]{11})$/i,
      translation: 'select.country.hr'
    },
    {
      code: 'DK',
      regex: /^(DK[\d]{8})$/i,
      translation: 'select.country.dk'
    },
    {
      code: 'EE',
      regex: /^(EE[\d]{9})$/i,
      translation: 'select.country.ee'
    },
    {
      code: 'FI',
      regex: /^(FI[\d]{8})$/i,
      translation: 'select.country.fi'
    },
    {
      code: 'GR',
      regex: /^(EL[\d]{9})$/i,
      translation: 'select.country.gr'
    },
    {
      code: 'IE',
      regex: /^(IE[\w]{8,9})$/i,
      translation: 'select.country.ie'
    },
    {
      code: 'LV',
      regex: /^(LV[\d]{11})$/i,
      translation: 'select.country.lv'
    },
    {
      code: 'LT',
      regex: /^LT([\d]{9}|([\d]{12}))$/i,
      translation: 'select.country.lt'
    },
    {
      code: 'MT',
      regex: /^(MT[\d]{8})$/i,
      translation: 'select.country.mt'
    },
    {
      code: 'RO',
      regex: /^(RO)?[\d]{2,10}$/i,
      translation: 'select.country.ro'
    },
    {
      code: 'SK',
      regex: /^(SK[\d]{10})$/i,
      translation: 'select.country.sk'
    },
    {
      code: 'SI',
      regex: /^(SI[\d]{8})$/i,
      translation: 'select.country.si'
    },
    {
      code: 'ES',
      regex: /^(ES[\D][\d]{7}[\D]|ES[\D][\d]{8}|ES[\d]{8}[\D])$/i,
      translation: 'select.country.es'
    },
    {
      code: 'SE',
      regex: /^(SE[\d]{12})$/i,
      translation: 'select.country.se'
    },
    {
      code: 'CY',
      regex: /^(CY[\w]{9})$/i,
      translation: 'select.country.cy'
    }
  ])

  return {
    countries
  }
}

export default useCountryOverview
