/* eslint-disable camelcase */
const cache = {}

async function loadAllPages (client, queryOptions) {
  const queryResults = []

  let fullPage = true
  for (let page = 1; fullPage; page++) {
    queryOptions.options.page = page
    const { data } = await client.query(queryOptions)
    const { datasource_entries } = data
    queryResults.push(...datasource_entries)
    fullPage = datasource_entries.length === 1000
  }

  return queryResults
}

export const loadTranslations = async ({ client, lang }) => {
  if (cache[lang]) {
    return cache[lang]
  }

  const datasourceQueryOptions = {
    type: 'datasource_entries',
    options: {
      datasource: 'translations',
      dimension: lang,
      per_page: 1000,
      cv: Date.now()
    }
  }
  let datasourceEntries = []
  try {
    datasourceEntries = await loadAllPages(client, datasourceQueryOptions)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error loading translations', e)
  }

  const translations = {}
  for (const { name, value, dimension_value } of datasourceEntries) {
    translations[name] = dimension_value || value
  }

  cache[lang] = translations

  return translations
}

function formatDate (locale, date, options = { dateStyle: 'short' }) {
  if (!(date instanceof Date)) {
    date = new Date(date)
  }
  return new Intl.DateTimeFormat(locale, options).format(date)
}

function formatNumber (locale, number) {
  return new Intl.NumberFormat(locale).format(number)
}

function formatCurrency (locale, value, currency = 'EUR') {
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value)
}

function composeTranslationKey (key, data) {
  let effectiveKey = key
  if (data.context) {
    effectiveKey += `_${data.context}`
  }
  if (data.count === 1) {
    effectiveKey += '_one'
  } else if (data.count > 1) {
    effectiveKey += '_other'
  }

  return effectiveKey
}

export default (nuxtApp, inject) => {
  const { $cms } = nuxtApp

  let translations = {}
  let locale = null

  nuxtApp.app.router.beforeEach(async (to, _from, next) => {
    const lang = await $cms.getLanguageFromRoute({ route: to })
    locale = (lang ?? $cms.defaultLanguageCode) === 'en' ? 'en-GB' : lang
    translations = await loadTranslations({ client: $cms, lang })
    next()
  })

  inject('t', (key, data) => {
    const translation = translations[key]

    if (!translation) {
      // eslint-disable-next-line no-console
      return data?.default ?? key
    }

    if (data) {
      const effectiveKey = composeTranslationKey(key, data)
      const effectiveTranslation = translations[effectiveKey]
      return effectiveTranslation.replace(/\{\{([^}]+)\}\}/g, (_, key) => {
        return data[key.trim()]
      })
    }

    return translation || key
  })

  inject('d', (date, options) => {
    return formatDate(locale, date, options)
  })

  inject('n', (number, format) => {
    return formatNumber(locale, number, format)
  })

  inject('c', (value, currency) => {
    return formatCurrency(locale, value, currency)
  })
}
