export const chevron = `
  <svg viewBox="0 0 100% 100%">
    <g>
      <path d="m 573.708,599.199 c 8.716,-8.717 15.581,-15.581 15.581,-15.581 0,0 0.495,0.495 1.316,1.317 0.833,-0.833 1.344,-1.344 1.344,-1.344 l 8.406,8.407 v 2.687 4.514 z m -71.821,0 h -31.214 c 6.096,-6.096 15.606,-15.608 15.606,-15.608 z m -118.721,-15.581 0.025,0.025 0.053,-0.052 15.608,15.608 h -31.267 c 8.717,-8.717 15.581,-15.581 15.581,-15.581 m -104.352,1.317 1.344,-1.344 15.607,15.608 h -33.848 l 15.581,-15.581 z m -88.718,14.264 h -31.215 c 6.096,-6.096 15.607,-15.608 15.607,-15.608 0,0 6.878,6.878 15.608,15.608 m -118.694,-15.556 0.052,-0.052 15.607,15.608 H 55.794 l 15.581,-15.581 z m 519.231,-42.692 -52.84,52.84 -20.598,-20.599 0.004,-0.004 -30.92,-30.919 -51.521,51.522 -20.598,-20.599 0.004,-0.004 -30.945,-30.945 -51.549,51.548 -20.598,-20.599 0.006,-0.004 -32.237,-32.237 -52.84,52.84 -20.597,-20.599 0.004,-0.004 -30.919,-30.919 -51.523,51.522 -20.597,-20.599 0.005,-0.004 -30.947,-30.945 -51.548,51.548 c 0,0 -14.316,-14.317 -19.078,-19.079 v -41.205 c 10.308,10.307 19.083,19.083 19.083,19.083 l 51.491,-51.491 0.027,0.025 0.052,-0.052 51.517,51.518 51.517,-51.518 51.518,51.518 51.492,-51.491 1.316,1.317 1.344,-1.344 51.518,51.518 51.49,-51.491 0.025,0.025 0.053,-0.052 51.518,51.518 51.517,-51.518 51.518,51.518 51.492,-51.491 c 0,0 0.495,0.495 1.316,1.317 l 1.344,-1.344 8.406,8.406 v 41.196 c -3.242,-3.242 -6.551,-6.552 -9.722,-9.723 m 0,-82.519 c -21.357,21.356 -52.84,52.839 -52.84,52.839 l -20.598,-20.598 0.004,-0.005 -30.92,-30.918 -51.521,51.521 -20.598,-20.599 0.004,-0.004 -30.945,-30.945 -51.549,51.548 -20.598,-20.598 0.006,-0.005 -32.237,-32.236 -52.84,52.839 -20.597,-20.598 0.004,-0.005 -30.919,-30.918 -51.523,51.521 -20.597,-20.599 0.005,-0.004 -30.947,-30.945 -51.548,51.548 c 0,0 -14.316,-14.317 -19.078,-19.079 v -41.205 l 19.083,19.083 51.491,-51.491
      0.027,0.025 0.052,-0.051 51.517,51.517 51.517,-51.517 51.518,51.517 51.492,-51.491 1.316,1.317 1.344,-1.343 51.518,51.517 51.49,-51.491 0.025,0.025 0.053,-0.051 51.518,51.517 30.92,-30.919 20.597,-20.598 51.518,51.517 51.492,-51.491 c 0,0 0.495,0.496 1.316,1.317 l 1.344,-1.343 8.406,8.406 v 41.196 c -3.242,-3.242 -6.551,-6.552 -9.722,-9.723 m -52.84,-29.68 -20.598,-20.598 0.004,-0.005 -30.92,-30.919 -51.521,51.522 -20.598,-20.598 0.004,-0.005 -30.945,-30.945 -51.549,51.548 -20.598,-20.598 0.006,-0.005 -32.237,-32.236 -52.84,52.839 -20.597,-20.598 0.004,-0.005 -30.919,-30.919 -51.523,51.522 -20.597,-20.598 0.005,-0.005 -30.947,-30.945 -51.548,51.548 c 0,0 -14.316,-14.316 -19.078,-19.078 v -41.206 l 19.083,19.083 51.491,-51.491 0.027,0.025 0.052,-0.051 51.517,51.517 30.919,-30.919 20.598,-20.598 51.518,51.517 51.492,-51.491 1.316,1.317 1.344,-1.343 51.518,51.517 51.49,-51.491 0.025,0.025 0.053,-0.051 51.518,51.517 30.92,-30.919 20.597,-20.598 51.518,51.517 51.492,-51.491 c 0,0 0.495,0.496 1.316,1.317 l 1.344,-1.343 8.406,8.406 v 41.196 c -3.242,-3.242 -6.551,-6.552 -9.723,-9.723 -21.357,21.356 -52.839,52.839 -52.839,52.839 m 52.839,-135.358 c -21.357,21.356 -52.839,52.839 -52.839,52.839 l -20.598,-20.598 0.004,-0.005 -30.92,-30.919 -51.521,51.521 -20.598,-20.598 0.004,-0.004 -30.945,-30.944 -51.549,51.547 -20.598,-20.598 0.006,-0.005 -32.237,-32.236 -52.84,52.839 -20.597,-20.598 0.004,-0.005 -30.919,-30.919 -51.523,51.521 -20.597,-20.598 0.005,-0.004 -30.947,-30.944 -51.548,51.547 c 0,0 -14.316,-14.317 -19.078,-19.079 v -41.205 l 19.083,19.083 51.491,-51.491 0.027,0.025 0.052,-0.052 51.517,51.518 30.919,-30.919 20.598,-20.599 51.518,51.518 51.492,-51.491 1.316,1.316 1.344,-1.343 51.518,51.518 51.49,-51.491 0.025,0.025 0.053,-0.052 51.518,51.518 30.92,-30.919 20.597,-20.599 51.518,51.518 51.492,-51.491 1.316,1.316
      1.344,-1.343 8.406,8.406 v 41.197 c -3.242,-3.242 -6.551,-6.553 -9.723,-9.723 m -52.839,-29.68 -20.598,-20.599 0.004,-0.005 -30.92,-30.919 -51.521,51.523 -20.598,-20.599 0.004,-0.004 -30.945,-30.945 -51.549,51.548 -20.598,-20.599 0.006,-0.004 -32.237,-32.237 -52.84,52.84 -20.597,-20.599 0.004,-0.005 -30.919,-30.919 -51.523,51.523 -20.597,-20.599 0.005,-0.004 -30.947,-30.945 -51.548,51.548 c 0,0 -14.316,-14.318 -19.078,-19.079 V 203.43 l 19.083,19.083 51.491,-51.492 0.027,0.026 0.052,-0.051 51.517,51.517 51.517,-51.517 51.518,51.517 51.492,-51.492 1.316,1.318 1.344,-1.344 51.518,51.518 51.49,-51.492 0.025,0.026 0.053,-0.051 51.518,51.517 51.517,-51.517 51.518,51.517 51.492,-51.492 c 0,0 0.495,0.496 1.316,1.318 l 1.344,-1.344 8.406,8.406 v 41.197 l -9.722,-9.723 c -21.358,21.355 -52.84,52.839 -52.84,52.839 m 0,-82.519 -20.598,-20.599 0.004,-0.004 -30.92,-30.919 -51.521,51.52 -20.598,-20.597 0.004,-0.004 -30.945,-30.946 -51.549,51.548 -20.598,-20.598 0.006,-0.004 -32.237,-32.237 -52.84,52.84 -20.597,-20.599 0.004,-0.004 -30.919,-30.919 -51.523,51.52 -20.597,-20.597 0.005,-0.004 -30.947,-30.946 -51.548,51.548 c 0,0 -14.316,-14.317 -19.078,-19.079 v -41.204 l 19.083,19.083 51.491,-51.492 0.027,0.025 0.052,-0.05 51.517,51.516 51.517,-51.516 51.518,51.517 51.492,-51.491 1.316,1.316 1.344,-1.343 51.518,51.518 51.49,-51.492 0.025,0.025 0.053,-0.05 51.518,51.516 51.517,-51.516 51.518,51.517 51.492,-51.491 1.316,1.316 1.344,-1.343 8.406,8.406 v 41.196 l -9.722,-9.723 z m 52.84,-135.359 -52.84,52.84 -20.598,-20.599 0.004,-0.005 -30.92,-30.918 -51.521,51.521 -20.598,-20.599 0.004,-0.004 -30.945,-30.945 -51.549,51.549 -20.598,-20.599 0.006,-0.005 -32.237,-32.236 -52.84,52.84 -20.597,-20.599 0.004,-0.005 L 174.489,47.154 122.966,98.675 102.369,78.076 102.374,78.072 71.427,47.127 19.879,98.676 c 0,0 -14.316,-14.318 -19.078,-19.079
      V 38.392 l 19.083,19.083 51.491,-51.492 0.027,0.027 0.052,-0.053 51.517,51.518 51.517,-51.518 51.518,51.518 51.492,-51.492 1.316,1.318 1.344,-1.344 51.518,51.518 51.49,-51.492 0.025,0.027 0.053,-0.053 51.518,51.518 30.92,-30.919 20.597,-20.599 51.518,51.518 51.492,-51.492 c 0,0 0.495,0.496 1.316,1.318 l 1.344,-1.344 8.406,8.406 v 41.196 c -3.242,-3.243 -6.551,-6.551 -9.722,-9.723 M 315.159,-0.354 h 33.022 l -16.511,16.51 z m 103.089,0 h 33.021 l -16.511,16.51 c 0,0 -10.504,-10.505 -16.51,-16.51 m 119.545,16.51 -16.511,-16.51 h 33.022 z m -517.914,0 -16.51,-16.51 H 36.39 C 27.235,8.8 19.879,16.156 19.879,16.156 m 103.087,0 c 0,0 -10.504,-10.505 -16.509,-16.51 h 33.021 c -9.155,9.153 -16.512,16.51 -16.512,16.51 m 103.035,0 -16.51,-16.51 h 33.021 z" style="fill:#ffffff;fill-opacity:0.5;"/>
      </g>
  </svg>
`
export const rim = `
  <svg viewBox="0 0 40 40">
    <g>
      <path d="M20 4a16 16 0 1 0 0 32 16 16 0 0 0 0-32Zm0 30a14 14 0 1 1 14-14 14.015 14.015 0 0 1-14 14Z"/>
      <path d="M32.613 23.1A13.677 13.677 0 0 0 33 20c-.001-.47-.027-.938-.079-1.405-.008-.076-.024-.15-.034-.226-.05-.395-.113-.786-.2-1.17-.009-.041-.021-.08-.031-.121-.096-.418-.213-.83-.35-1.237l-.007-.019a12.932 12.932 0 0 0-1.367-2.848c-.049-.077-.092-.159-.143-.235a13.088 13.088 0 0 0-.832-1.1l-.02-.021a12.904 12.904 0 0 0-.937-.983A13.03 13.03 0 0 0 26.054 8.5c-.019-.009-.038-.017-.056-.027-.342-.178-.69-.344-1.049-.492-.058-.024-.118-.041-.176-.064-.323-.128-.65-.25-.985-.353-.117-.035-.24-.059-.358-.092-.284-.077-.566-.157-.857-.215-.206-.042-.418-.064-.628-.1-.21-.036-.421-.073-.636-.095A14.508 14.508 0 0 0 20 7c-.3 0-.6.025-.895.045-.309.02-.614.054-.916.1h-.036a12.9 12.9 0 0 0-2.626.661l-.145.053a13.013 13.013 0 0 0-4.895 3.303l-.076.083c-.366.399-.707.82-1.021 1.26-.188.266-.362.54-.53.82l-.049.082A12.941 12.941 0 0 0 7.4 16.854l-.012.047a13.677 13.677 0 0 0-.333 2.037A11.67 11.67 0 0 0 7 20c.001.47.027.938.079 1.405.008.076.024.15.034.226.05.395.113.786.2 1.17.009.041.021.08.031.121.096.418.213.83.35 1.237l.007.019c.146.43.315.85.506 1.262.252.548.542 1.078.868 1.586.049.077.092.159.143.235.258.38.536.745.832 1.095l.02.021c.292.345.602.674.928.986a13.03 13.03 0 0 0 2.948 2.137c.018.009.038.017.056.027.342.178.69.344 1.049.492.057.024.117.041.174.064.324.128.651.25.987.353.117.035.24.059.358.092.284.077.566.157.857.215.206.042.418.064.628.1.21.036.421.073.636.095.431.043.867.066 1.309.066.3 0 .6-.025.895-.045.309-.021.614-.055.916-.1l.036-.005c.896-.128 1.776-.35 2.626-.661l.145-.053a13.011 13.011 0 0 0 4.895-3.303l.076-.083c.366-.397.707-.815 1.021-1.254.188-.266.362-.54.53-.82l.049-.082a12.938 12.938 0 0 0 1.41-3.446l.014-.052Zm-2.374.893c-.157.411-.34.812-.546 1.2l-6.311-1.632A4.926 4.926 
      0 0 0 24.5 21.99l.763-.778 5.415 1.4a11.2 11.2 0 0 1-.387 1.251c-.021.043-.035.088-.052.13Zm-5.294 5.82-1.13-4.074 4.7 1.215a11.049 11.049 0 0 1-3.57 2.859Zm-1.87.742c-.422.122-.851.218-1.285.287l-.11.017c-.441.068-.886.11-1.332.124L18.6 24.7c.453.143.925.22 1.4.226.162 0 .315-.033.474-.048l1.105.285 1.5 5.4-.004-.008Zm-7.09-.324a10.7 10.7 0 0 1-.985-.444l-.065-.031a10.84 10.84 0 0 1-.966-.565l2.966-3.018 1.3 4.672c-.757-.123-1.5-.324-2.216-.6l-.034-.014Zm-6.576-7.3a10.823 10.823 0 0 1-.212-.9c-.017-.091-.028-.185-.043-.277-.039-.235-.077-.47-.1-.71A10.558 10.558 0 0 1 9 20c0-.211.02-.416.032-.624l4.092 1.058-3.4 3.459c-.114-.3-.214-.606-.3-.918l-.015-.044Zm.352-6.926c.157-.411.34-.812.546-1.2l6.311 1.632c-.47.445-.849.98-1.113 1.571l-.763.778-5.415-1.4c.104-.424.234-.842.387-1.251.016-.041.03-.085.047-.127v-.003Zm5.294-5.82 1.13 4.074-4.7-1.215a11.049 11.049 0 0 1 3.57-2.859Zm1.87-.742c.422-.122.851-.218 1.285-.287l.11-.017c.441-.068.886-.11 1.332-.124L21.4 15.3a4.857 4.857 0 0 0-1.4-.226c-.162 0-.316.033-.474.048l-1.105-.285-1.5-5.395.004.001Zm7.09.324c.335.131.662.279.982.443l.065.031c.331.172.654.36.966.565l-2.966 3.018-1.3-4.672a11.1 11.1 0 0 1 2.216.6l.037.015Zm5.32 4.44-4.562 4.643a4.86 4.86 0 0 0-.8-1.732l-.3-1.068 3.927-3.985c.666.64 1.25 1.36 1.737 2.145l-.002-.003ZM22.922 20a2.89 2.89 0 0 1-.163.919 3.15 3.15 0 0 1-.232.52c-.021.037-.039.077-.062.113a2.713 2.713 0 0 1-.3.4 2.875 2.875 0 0 1-.492.443c-.042.029-.085.055-.128.081-.284.18-.597.309-.925.381-.13.024-.26.039-.391.046-.075.006-.148.023-.225.023a2.884 2.884 0 0 1-.486-.05 2.91 2.91 0 0 1-2.033-1.413c-.02-.033-.037-.067-.055-.1a2.751 2.751 0 0 1-.188-2.277c.061-.18.139-.354.232-.52.021-.037.038-.076.061-.112a2.914 2.914 0 0 1 .792-.842c.041-.029.085-.055.129-.081.283-.18.596-.309.923-.381.132-.024.266-.04.4-.046.074-.006.145-.023.221-.023.165.003.328.02.49.05.064.012.128.023.192.039a2.946
      2.946 0 0 1 1.835 1.376l.056.1c.217.42.336.882.349 1.354Zm-12.257 5.79 4.562-4.643c.15.625.422 1.213.8 1.732l.3 1.068-3.927 3.988c-.666-.64-1.25-1.36-1.737-2.145h.002Zm20.3-5.166-4.092-1.058 3.4-3.459c.114.3.214.606.3.918l.011.041c.082.294.154.593.212.9.017.091.028.185.043.277.039.235.077.47.1.71.033.346.053.7.053 1.05.008.208-.012.413-.024.621h-.003Z"/>
    </g>
  </svg>
`

export const rimSearch = `
  <svg viewBox="0 0 40 40">
    <g>
      <path d="M18.349 33.894C11.406 33.073 6 27.16 6 20c0-7.72 6.28-14 14-14 7.16 0 13.073 5.406 13.894 12.349.833.86 1.522 1.857 2.041 2.952.035-.43.066-.862.066-1.301C36 11.163 28.837 4 20 4 11.163 4 4 11.163 4 20s7.163 16 16 16c.439 0 .87-.031 1.301-.066a11.002 11.002 0 0 1-2.952-2.04Z"/>
      <path  d="M15.818 30.155c-.276-.114-.55-.233-.815-.368l-.065-.031c-.332-.172-.655-.36-.966-.565l1.212-1.233A10.977 10.977 0 0 1 15 26c0-.252.021-.499.038-.747l-2.635 2.682a11.05 11.05 0 0 1-1.737-2.145l4.562-4.643c.095.397.253.766.437 1.118a10.96 10.96 0 0 1 1.461-2.746 3.193 3.193 0 0 1 .347-.959c.021-.037.038-.076.061-.112.088-.143.194-.273.305-.398.148-.164.31-.315.491-.443.042-.029.086-.055.129-.081.281-.176.59-.309.924-.381.041-.009.085-.009.128-.015a10.926 10.926 0 0 1 5.108-2.036l2.978-3.031a11.05 11.05 0 0 1 1.737 2.145l-1.021 1.039a10.948 10.948 0 0 1 4.391 2.045c-.007-.031-.009-.064-.016-.095-.009-.041-.022-.08-.031-.121a13.076 13.076 0 0 0-.35-1.237l-.007-.018a12.82 12.82 0 0 0-.506-1.262v-.002c-.254-.55-.543-1.08-.868-1.585-.049-.078-.092-.16-.143-.236a13.032 13.032 0 0 0-.832-1.095l-.019-.021a13.306 13.306 0 0 0-.929-.986l-.002-.002a13.013 13.013 0 0 0-2.947-2.133c-.018-.01-.037-.017-.056-.027-.342-.178-.69-.345-1.049-.492-.058-.024-.118-.041-.176-.064-.323-.128-.65-.25-.985-.352-.117-.036-.24-.06-.358-.092-.283-.077-.566-.157-.857-.215-.206-.042-.418-.064-.628-.096-.212-.032-.421-.074-.637-.095-.43-.043-.867-.066-1.309-.066-.302 0-.598.025-.895.045a13.085 13.085 0 0 0-.952.1c-.91.13-1.787.353-2.626.66l-.145.053a13.017 13.017 0 0 0-4.896 3.303c-.026.027-.05.055-.075.083-.364.399-.707.817-1.02 1.259-.188.265-.362.54-.53.819l-.049.081a12.91 12.91 0 0 0-1.41 3.447l-.012.047a12.67 12.67 0 0 0-.201.994c-.056.344-.103.69-.132 1.042-.033.35-.054.704-.054 1.063 0 .475.029.943.079 1.405.008.076.025.15.034.225.05.396.113.786.197
      1.17.009.041.022.08.031.121.097.421.214.833.35 1.237l.007.018c.147.432.316.852.506 1.262v.002c.254.55.543 1.08.868 1.585.049.078.092.16.143.236.257.381.537.744.832 1.095l.019.021a13.306 13.306 0 0 0 3.878 3.121c.018.01.037.017.055.027.342.178.691.345 1.049.493.057.023.116.041.174.063.324.128.651.251.987.353.117.036.24.06.358.092.241.066.482.128.727.183a10.95 10.95 0 0 1-1.479-2.555l.001.005Zm8.164-20.398.034.012c.336.131.663.281.982.443l.065.031c.332.172.655.36.966.565l-2.965 3.018-1.296-4.672a11.03 11.03 0 0 1 2.216.602l-.002.001Zm-7.056-.312c.418-.122.848-.216 1.285-.287l.11-.017c.436-.066.88-.11 1.332-.124l1.744 6.286a4.879 4.879 0 0 0-1.396-.225c-.162 0-.316.032-.474.048l-1.105-.286-1.496-5.395Zm-1.87.742 1.13 4.074-4.7-1.215a11.05 11.05 0 0 1 3.57-2.859Zm-5.341 5.949.047-.129c.159-.412.339-.815.546-1.2l6.311 1.632a4.926 4.926 0 0 0-1.113 1.571l-.764.777-5.415-1.4c.105-.427.236-.844.387-1.25l.001-.001Zm.011 7.756c-.114-.3-.216-.606-.305-.918l-.011-.041a11.046 11.046 0 0 1-.213-.897c-.017-.091-.028-.185-.042-.278-.039-.235-.078-.47-.101-.709a11.05 11.05 0 0 1-.053-1.05c0-.211.02-.416.032-.624l4.092 1.058-3.399 3.459Z"/>
      <path d="M35.707 34.293 32.314 30.9A7.957 7.957 0 0 0 34 26a8 8 0 1 0-8 8 7.955 7.955 0 0 0 4.9-1.686l3.393 3.393a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414ZM20 26c0-3.308 2.692-6 6-6s6 2.692 6 6-2.692 6-6 6-6-2.692-6-6Z"/>
    </g>
  </svg>
`

export const completeWheel = `
  <svg viewBox="0 0 40 40">
    <g>
      <path d="M35.222 15.083c0-.013-.009-.025-.013-.038a16.014 16.014 0 0 0-5.727-7.911.96.96 0 0 0-.158-.119A15.914 15.914 0 0 0 20.018 4H20a15.91 15.91 0 0 0-9.462 3.119 16.005 16.005 0 0 0-5.736 7.9c-.006.016-.013.031-.018.048a15.925 15.925 0 0 0-.006 9.852c0 .013.009.025.013.038a16.012 16.012 0 0 0 5.7 7.894c.046.042.096.08.15.112a15.92 15.92 0 0 0 18.706 0c.052-.033.102-.07.148-.111a16.009 16.009 0 0 0 5.7-7.871c.006-.016.013-.031.018-.048 1.043-3.2 1.045-6.65.006-9.852l.003.002ZM33.9 21.625l-1.272-2.58a1 1 0 1 0-1.795.885l2.345 4.757a14.005 14.005 0 0 1-2.884 4.771l.483-2.809a1.001 1.001 0 1 0-1.97-.339l-.9 5.235a13.936 13.936 0 0 1-5.163 2.182l2.069-2.014a1 1 0 1 0-1.395-1.434l-3.8 3.7a13.885 13.885 0 0 1-5.442-1.267l2.835-.41a1 1 0 0 0 .846-1.133.99.99 0 0 0-1.133-.846l-5.241.759a14.101 14.101 0 0 1-3.672-4.226l2.54 1.336a1.002 1.002 0 0 0 1.49-.786 1 1 0 0 0-.559-.983l-4.7-2.475a13.575 13.575 0 0 1-.482-5.573l1.272 2.58a1 1 0 0 0 .9.558c.153 0 .304-.034.442-.1a1 1 0 0 0 .455-1.34l-2.345-4.76a14.006 14.006 0 0 1 2.884-4.771l-.483 2.809a1 1 0 1 0 1.97.339l.9-5.235a13.936 13.936 0 0 1 5.163-2.182L15.19 8.287a1.002 1.002 0 0 0 1.4 1.434l3.8-3.7c1.881.05 3.733.48 5.442 1.267l-2.835.41a1 1 0 1 0 .287 1.979l5.241-.759a14.1 14.1 0 0 1 3.672 4.226l-2.54-1.336a1 1 0 1 0-.931 1.769l4.7 2.475a13.57 13.57 0 0 1 .474 5.573Z"/>
      <path d="M27.807 13.76a10.08 10.08 0 0 0-2.113-1.971 9.94 9.94 0 0 0-5.144-1.757c-.183-.01-.364-.028-.55-.028-.502.004-1.004.045-1.5.124a9.904 9.904 0 0 0-2.766.842 9.937 9.937 0 0 0-5.04 5.393 9.883 9.883 0 0 0-.652 2.818 9.73 9.73 0 0 0-.042.823c.007 2.268.78 4.466 2.193 6.24a10.084 10.084 0 0 0 2.113 1.971 9.94 9.94 0 0 0 5.144 1.757c.183.01.364.028.55.028a10.035 10.035 0 0 0 1.5-.124 9.901 9.901 0 0 0 2.766-.842 9.937 9.937 0 0 0 5.04-5.393 9.87 9.87 0 0 0 .652-2.818A9.73 9.73 0 0 0 30 20a10.065 
      10.065 0 0 0-2.193-6.24Zm-1.995.759c.204.218.397.446.576.685L22.954 18.7l-.275-.991 3.133-3.19Zm-6.774-2.455 1.311 4.727-1-.257-1.2-4.312c.293-.07.59-.122.889-.158Zm-2.761.861.84 3.03-3.407-.881a8.05 8.05 0 0 1 2.567-2.149Zm-3.635 3.938 4.758 1.229-.722.735-4.335-1.121c.084-.287.183-.568.299-.843Zm-.057 6.125A7.944 7.944 0 0 1 12 20c0-.106.012-.208.016-.313l3.04.786-2.471 2.515Zm1.6 2.493a8.22 8.22 0 0 1-.576-.685l3.437-3.496.275.991-3.136 3.19Zm1.549 1.277 2.2-2.239.941 3.393a7.934 7.934 0 0 1-3.138-1.154h-.003ZM19 20a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1.962 7.936-1.311-4.727 1 .257 1.2 4.312c-.293.07-.59.122-.889.158Zm1.1-12.455-.941-3.393a7.935 7.935 0 0 1 3.142 1.154l-2.201 2.239Zm1.661 11.594-.84-3.03 3.407.881a8.05 8.05 0 0 1-2.567 2.149Zm3.635-3.938L22.6 21.908l.722-.735 4.335 1.121a7.814 7.814 0 0 1-.299.843Zm.626-2.824-3.04-.786 2.471-2.515c.386.949.585 1.964.585 2.988 0 .106-.012.208-.016.313Z"/>
    </g>
  </svg>
`

export const rdks = `
  <svg viewBox="0 0 40 40">
    <g>
      <path d="M20.092 4.908 4.536 20.464a5.015 5.015 0 0 0 0 7.072L5.95 28.95a5.015 5.015 0 0 0 7.07 0l2.83-2.829 1.414 1.415a2 2 0 0 0 2.828 0l4.95 4.95a2 2 0 0 0 2.828 0l3.536 3.535a2 2 0 0 0 2.828 0l1.414-1.414a2 2 0 0 0 0-2.829l-3.535-3.535a2 2 0 0 0 0-2.829l-4.95-4.95a2 2 0 0 0 0-2.828l-1.414-1.414 2.828-2.829a5.015 5.015 0 0 0 0-7.07l-1.414-1.415a5.015 5.015 0 0 0-7.071 0Zm-2.828 5.657 5.656 5.657-7.07 7.07-5.658-5.656 7.072-7.071Zm-5.657 16.97a3.004 3.004 0 0 1-4.243 0L5.95 26.121a3.004 3.004 0 0 1 0-4.242l2.828-2.829 5.657 5.657-2.828 2.828Zm22.627 5.657-1.414 1.415-3.536-3.536 1.415-1.414 3.535 3.535Zm-3.536-6.364-4.242 4.243-4.95-4.95 4.243-4.242 4.95 4.95Zm-4.95-7.778-7.07 7.071-1.415-1.414 7.071-7.071 1.415 1.414Zm1.415-11.313a3.004 3.004 0 0 1 0 4.242l-2.829 2.829-5.656-5.657 2.828-2.829a3.004 3.004 0 0 1 4.243 0l1.414 1.415Z"/>
    </g>
  </svg>
`

export const tire = `
  <svg viewBox="0 0 40 40">
    <g>
      <path d="M35.222 15.083c0-.013-.009-.025-.013-.038a16.015 16.015 0 0 0-5.728-7.912.939.939 0 0 0-.157-.117A15.9 15.9 0 0 0 20.017 4H20a15.91 15.91 0 0 0-9.322 3.014.959.959 0 0 0-.145.108 16.014 16.014 0 0 0-5.748 7.946 15.919 15.919 0 0 0-.007 9.853c0 .013.009.025.013.038a16.012 16.012 0 0 0 5.7 7.893c.047.043.098.082.152.115a15.923 15.923 0 0 0 18.7 0 1.06 1.06 0 0 0 .148-.111 16.009 16.009 0 0 0 5.7-7.871c.006-.016.013-.031.018-.048 1.043-3.2 1.045-6.65.006-9.852l.007-.002ZM33.9 21.625l-1.272-2.58a1 1 0 0 0-1.795.885l2.345 4.757a14.006 14.006 0 0 1-2.884 4.771l.483-2.809a1 1 0 1 0-1.97-.339l-.9 5.235a13.937 13.937 0 0 1-5.163 2.182l2.069-2.014a1 1 0 1 0-1.395-1.434l-3.8 3.7a13.885 13.885 0 0 1-5.442-1.267l2.835-.41a1 1 0 0 0 .846-1.133.989.989 0 0 0-1.133-.846l-5.241.759a14.1 14.1 0 0 1-3.672-4.226l2.54 1.336a1 1 0 1 0 .931-1.769l-4.7-2.475a13.58 13.58 0 0 1-.482-5.574l1.272 2.581a1 1 0 1 0 1.794-.885l-2.342-4.757a14.005 14.005 0 0 1 2.884-4.771l-.483 2.809a1 1 0 0 0 1.97.339l.9-5.234a13.914 13.914 0 0 1 5.163-2.183L15.19 8.287a1.002 1.002 0 1 0 1.4 1.434l3.8-3.7c1.881.05 3.733.48 5.442 1.267l-2.835.41a1 1 0 1 0 .287 1.979l5.241-.759a14.1 14.1 0 0 1 3.672 4.226l-2.54-1.336a1 1 0 1 0-.931 1.769l4.7 2.475a13.57 13.57 0 0 1 .474 5.573Z"/>
      <path d="M20 10a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8Z"/>
    </g>
  </svg>
`

export const steelrim = `
<svg viewBox="0 0 40 40">
    <g>
      <path d="M20 4a16 16 0 1 0 0 32 16 16 0 0 0 0-32Zm0 30a14 14 0 1 1 14-14 14.015 14.015 0 0 1-14 14Z"/>
      <path d="M20 7a13 13 0 1 0 0 26 13 13 0 0 0 0-26Zm0 24a11 11 0 1 1 11-11 11.013 11.013 0 0 1-11 11Z"/>
      <path d="M20 17a3 3 0 1 0 0 5.999A3 3 0 0 0 20 17Zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"/>
      <path d="M30 20a2.99 2.99 0 0 0-2.805-2.98 2.986 2.986 0 0 0-4.215-4.215 2.985 2.985 0 0 0-5.96 0 2.986 2.986 0 0 0-4.215 4.215 2.985 2.985 0 0 0 0 5.96 2.986 2.986 0 0 0 4.215 4.22 2.985 2.985 0 0 0 5.96 0 2.986 2.986 0 0 0 4.215-4.215A2.991 2.991 0 0 0 30 20Zm-8.031 4.753a2.951 2.951 0 0 0-3.938 0 2.946 2.946 0 0 0-2.784-2.784 2.95 2.95 0 0 0 0-3.938 2.946 2.946 0 0 0 2.784-2.784 2.951 2.951 0 0 0 3.938 0 2.947 2.947 0 0 0 2.784 2.784 2.951 2.951 0 0 0 0 3.938 2.947 2.947 0 0 0-2.784 2.784Zm2.274-10.41a1 1 0 1 1 1.414 1.415 1 1 0 0 1-1.414-1.415ZM20 12a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm-5.657 2.343a1 1 0 1 1 1.415 1.414 1 1 0 0 1-1.415-1.414ZM12 20a1 1 0 1 1 2.002 0A1 1 0 0 1 12 20Zm3.757 5.657a1 1 0 1 1-1.414-1.415 1 1 0 0 1 1.414 1.415ZM20 28a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm5.657-2.343a1 1 0 1 1-1.414-1.413 1 1 0 0 1 1.414 1.413ZM27 21a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"/>
    </g>
  </svg>`
