import { initializeFaro } from '@grafana/faro-web-sdk'

export default ({ $config: { grafanaFaroUrl, grafanaFaroApiKey } }) => {
  if (process.client && grafanaFaroApiKey && grafanaFaroUrl) {
    initializeFaro({
      url: grafanaFaroUrl,
      apiKey: grafanaFaroApiKey,
      app: {
        name: 'frontend',
        version: '1.0.0'
      }
    })
  }
}
