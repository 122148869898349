import { createCookies } from '@vueuse/integrations/useCookies'

let useUniversalCookies // eslint-disable-line

const cookiesPlugin = ({ req }) => {
  useUniversalCookies = createCookies(req)
}

export default cookiesPlugin

export { useUniversalCookies }
