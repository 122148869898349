export default function ({ route }) {
  if (process.client) {
    const userinfoCookie = getCookie('b4t-userinfo')
    if (!userinfoCookie) { return }
    const userInfoString = decodeURIComponent(userinfoCookie)
    const userInfo = JSON.parse(userInfoString)
    if (userInfo.companyState === 'VERIFIED' &&
      userInfo.isCompanyActive === true &&
      userInfo.customer.roles.includes('noticed_marketing_contracts') &&
      userInfo.customer.roles.includes('consent_ok')) {
      return
    }
    window.location.href = route.fullPath
  }
}

function getCookie (name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) { return parts.pop().split(';').shift() }
}
