//
//
//
//
//
//
//
//

import { defineComponent, toRefs } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    error: {
      type: Object,
      default: undefined
    }
  },
  setup (props) {
    const { error } = toRefs(props)
    const statusCode = error?.value?.statusCode || 404
    const genericErrorPage = `/error/${statusCode}`

    return {
      genericErrorPage
    }
  }
})
