import Vue from 'vue'
import dayjs from 'dayjs'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import useCountryOverview from '~/composables/useCountryOverview'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

const enabledRules = [
  'required',
  'alpha',
  'email',
  'size',
  'numeric',
  'ext',
  'min',
  'max'
]

enabledRules.forEach((rule) => {
  extend(rule, {
    ...rules[rule], // eslint-disable-line import/namespace
    message: `forms.errors.${rule}`
  })
})

/* custom rules below */

extend('requiredNotEmptyObject', {
  validate: (value) => {
    return !value || Object.keys(value).length > 0
  },
  message: 'forms.errors.required'
})

extend('validAge', {
  validate: (value) => {
    const today = dayjs()
    const diff = today.diff(value, 'year')
    return diff >= 18 && diff < 100
  },
  message: 'forms.errors.validAge'
})

extend('taxid', {
  params: ['country'],
  validate: (value, { country: countryCode }) => {
    const { countries } = useCountryOverview()

    const validateValue = value.replace(/[\s]/g, '')

    if (countryCode && validateValue) {
      const countryRegex = countries.value.find(country => country.code === countryCode)
      return countryRegex?.regex.test(validateValue) ?? false
    }
    return false
  },
  message: 'forms.errors.validTax'
})

extend('iban', {
  validate: (value) => {
    const ibanCodeLengths = { AT: 20, CH: 21, DE: 22, BE: 16, NL: 18, LU: 20 }
    const iban = String(value).toUpperCase().replace(/[^A-Z0-9]/g, '') // keep only alphanumeric characters
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) // match and capture (1) the country code, (2) the check digits, and (3) the rest
    if (!code || iban.length !== ibanCodeLengths[code[1]]) { // check syntax and length
      return false
    }

    // rearrange country code and check digits, and convert chars to ints
    const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, letter => letter.charCodeAt(0) - 55)
    let checksum = digits.slice(0, 2)
    for (let offset = 2; offset < digits.length; offset += 7) {
      const fragment = String(checksum) + digits.substring(offset, offset + 7)
      checksum = parseInt(fragment, 10) % 97
    }
    return checksum
  },
  message: 'forms.errors.isIban'
})

extend('noTrailingZero', {
  validate: (value) => {
    return !value.startsWith('0')
  },
  message: 'forms.errors.noTrailingZero'
})
